<template>
  <div class="login-wrap">
    <div class="title">主持人注册</div>
    <div class="login-box">
      <div class="box-title fs28 ">手机号</div>
      <div class="field-box fs36">
        <van-field
          @input="accountChange"
          v-model.trim="account"
          clearable
          placeholder="请输入"
          maxlength="11"
          type="tel"
        />
      </div>
      <div class="box-title fs28 ">验证码</div>
      <div class="field-box fs36 dfr cross-center main-between">
        <div class="code-box">
          <van-field
            class="code"
            maxlength="4"
            @input="codeChange"
            v-model.trim="code"
            clearable
            type="number"
            placeholder="请输入"
          />
        </div>

        <div class="send-code-wrap  fs24 dfr flex-center ">
          <div v-if="isSend" class="send-code w100 h100 dfr flex-center">
            {{ CD }}s后重发
          </div>
          <div
            v-else
            @click="sendCode"
            :class="[account ? 'active' : '']"
            class="send-code w100 h100 dfr flex-center"
          >
            获取验证码
          </div>
        </div>
      </div>
    </div>

    <div class="btn-box-wrap fs36 fc666">
      <div @click="login" class="btn-box" :class="[disabled ? 'disabled' : '']">
        注册
      </div>
    </div>
    <!-- <div style="margin-top:.2rem" class="fs28 fc999">
      验证码 9999 ， 需要点击获取验证码才可成功登录，正式版会删除。
    </div> -->
  </div>
</template>

<script>
// import { loginedSaveJWT, homeShare } from "../utils/logon";
export default {
  props: {},
  data() {
    return {
      account: "",
      code: "",
      isSend: false,
      disabled: true,
      CD: 60,
      cdTimer: null,
      backStatus: "1", // 1 首页  2我的   3invite  4填写信息
      targetPageMap: {
        1: {
          path: "home",
          query: ""
        },
        2: {
          path: "me",
          query: ""
        },
        3: {
          path: "invite",
          query: {
            meetingId: "",
            userId: ""
          }
        },
        4: {
          path: "perfect",
          query: { type: 0 }
        }
      },
      meetingId: "",
      userId: ""
    };
  },

  created() {
    // 0 手机登陆 1 微信登陆 2匿名登陆
    this.$localStore.set(`loginType`, 0);
    // homeShare();
    this.hostRegisterShare();
  },
  mounted() {
    this.$title("云派私董会");
  },
  methods: {
    // 未生效，分享出去的是首页
    hostRegisterShare() {
      setTimeout(() => {
        const shareLink =
          location.origin + location.pathname + `#/hostRegister`;
        this.shareLink = shareLink;
        const shareInfo = {
          title: "云派私董会",
          desc: `邀请您注册主持人`,
          // 朋友圈标题描述
          longTitle: `云派私董会邀请您注册主持人`,
          link: shareLink,
          imgUrl: [location.origin + "/share-icon.png"]
        };
        this.$bus.$emit("hasShare", shareInfo);
      }, 100);
    },

    accountChange() {
      this.isCanSubmit();
    },
    async sendCode() {
      const telReg = /^[1]([3-9])[0-9]{9}$/;
      if (!telReg.test(this.account)) {
        this.$toast("手机格式不正确");
        return;
      }
      const resp = await this.$API
        .post("sendCode", { phone: this.account }, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.$toast("发送成功");
        this.CDHandle();
      }
    },
    CDHandle() {
      this.CD = 60;
      this.isSend = true;
      this.cdTimer = setInterval(() => {
        if (this.CD <= 0) {
          this.CD = 0;
          this.cdTimer = null;
          clearInterval(this.cdTimer);
          this.isSend = false;
          return;
        }
        this.CD--;
      }, 1000);
    },
    codeChange() {
      this.isCanSubmit();
    },
    isCanSubmit() {
      const reg = /^\s*$/;
      if (reg.test(this.account)) {
        this.disabled = true;
        return false;
      }
      if (reg.test(this.code)) {
        this.disabled = true;
        return false;
      }
      this.disabled = false;
      return true;
    },
    async login() {
      const reg = /^\s*$/;
      if (reg.test(this.account)) {
        this.$toast("账户名不能为空");
        return false;
      }
      if (reg.test(this.code)) {
        this.$toast("验证码不能为空");
        return false;
      }

      const data = {
        phone: this.account,
        captcha: this.code
      };
      const resp = await this.$API
        .post("hostRegister", data, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.$localStore.set(`JWT`, resp.data.token);
        this.$localStore.set(`isLogin`, true);
        this.$go("perfect", {
          type: 1
        });
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.login-text {
  margin-top: 0.6rem;
}

.login-wrap {
  padding: 0.6rem;

  .title {
    font-size: 0.6rem;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
  }

  .login-box {
    margin-top: 0.32rem;

    .box-title {
      margin-top: 0.48rem;
    }

    .field-box {
      border-bottom: 0.01rem solid #ddd;
      padding-bottom: 0.02rem;
    }
  }
  .code-box {
    width: 4.6rem;
  }
  .send-code-wrap {
    width: 1.52rem;
    height: 0.56rem;

    .send-code {
      background: #eeeeee;
      color: #999;
      border-radius: 0.28rem;
      &.active {
        color: #7c5a2f;
        background: linear-gradient(270deg, #e3b06b 0%, #fddc98 100%);
        &:active {
          opacity: 0.7;
        }
      }
    }
  }

  .btn-box-wrap {
    margin-top: 0.6rem;
    border-radius: 0.48rem;

    .btn-box {
      width: 6.3rem;
      height: 0.96rem;
      border-radius: 0.48rem;
      background: linear-gradient(270deg, #e3b06b 0%, #fddc98 100%);
      box-shadow: 0px 4px 12px 0px rgba(227, 176, 107, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.36rem;
      color: #7c5a2f;

      &.disabled {
        background: #cccccc;
        color: #fff;
        box-shadow: none;
      }

      &:active {
        opacity: 0.5;
      }
    }
  }
}
</style>
